<template>
  <div class="mainpage" style="margin-top:20px;">
    <div class="jonvo-topbar row">
      <div class="col col-lg-10 col-md-8 col-sm-6">
        <h1 style="color:#d6d6d6;padding-left:20px;">Jon Vokal</h1>
      </div>
      <div class="col-lg-2 col-md-4 col-sm-6" style="text-align: right;padding-right:25px;">
        <span class="jonvo-weblink">
          <a href="https://www.facebook.com/jon.vokal">
            <font-awesome-icon icon="fa-brands fa-facebook" />
          </a>
        </span>
<!-- 
        <span class="jonvo-weblink">
          <a href="https://facebook.com">
            <font-awesome-icon icon="fa-brands fa-soundcloud" />
          </a>
        </span> -->
        
        <span class="jonvo-weblink">
          <a href="https://www.instagram.com/jonvokal">
            <font-awesome-icon icon="fa-brands fa-instagram" />
          </a>
        </span>
<!-- 
        <span class="jonvo-weblink">
          <a href="https://facebook.com">
            <font-awesome-icon icon="fa-brands fa-bandcamp" />
          </a>
        </span> -->
<!-- 
        <span class="jonvo-weblink">
          <a href="https://facebook.com">
            <font-awesome-icon icon="fa-brands fa-spotify" />
          </a>
        </span> -->
<!-- 
        <span class="jonvo-weblink">
          <a href="https://facebook.com">
            <font-awesome-icon icon="fa-solid fa-music" />
          </a>
        </span> -->

        <span class="jonvo-weblink">
          <a href="https://www.tiktok.com/@jonvokal">
            <font-awesome-icon icon="fa-brands fa-tiktok" />
          </a>
        </span>
      </div>
    </div>
    <div class="jonvo-main-body row" style="background-color:#000;color:#d6d6d6;display: flex ">
      <div class="col col-lg-3 col-sm-6" style="align-content: center">
        <img alt="Vue logo" src="./../assets/jonv.jpg">
      </div>
      <div class="col-lg-6 col-md-12" style="min-height:600px;padding:10px;">
        <div class="jonvo-bio-content" id="bio" style="color:#d6d6d6;margin:15px;padding-left:20px;align-self: center">
          <span style="font-size:18px;font-weight:700;color:#fff;">
          Jon Vokal is a Detroit born antipop artist, guitarist, and podcaster, currently residing in Austin, Texas. His debut album, "Liminal Space" will be released worldwide in 2024. Pulling from a wide range of influences, "Liminal Space" is a unique blend of pop and math rock music. He is the guitarist of the band Blank Slate, an alternative project founded in 2017 with multiple releases. Jon's music has totaled over 100K streams, and his podcast over 4M views. Jon is an endorsed artist with Balaguer Guitars and BadCat Amplification. Jon is a seasoned live musician, playing to crowds and opening for touring packages for over a decade.</span>
        </div>
      </div>
      <div class="col-lg-3 col-md-12" style="padding:10px;height:600px;">
        <div class="music-content" style="color:#000;margin:15px;margin-right:30px;font-size:13px">
          <div class="col" style="max-width: 400px;">
            <audio ref="audio" id="jonvoPlayer" controls autoplay @ended="playNext">
              <source :src="'/music/'+currentSong" type="audio/wav">
              Your browser does not support the audio element.
            </audio>
          </div>
          <div class="row">
            <div v-for="(song, index) in songs" :key="song" style="padding:5px;cursor:pointer;" @click="setCurrentSong(index, song)">
              {{song.number}}
              <span v-if="currentSong == song.url" style="border-bottom: 2px solid;padding-bottom:3px;border-color: #626162">
                {{song.title.substr(0, song.title.length - 4)}}
              </span>
              <span v-if="currentSong != song.url">{{song.title.substr(0, song.title.length - 4)}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data(){
    return {
      currentSong:'',
      currentSongIndex: 0,
      songs:[
        { number : "1.", title : "The Ever Eclipse.mp3", url : "01.mp3" },
        { number : "2.", title : "Near Sol.mp3", url : "02.mp3" },
        { number : "3.", title : "Feign.mp3", url : "03.mp3" },
        { number : "4.", title : "Lunar Lakes.mp3", url : "04.mp3" },
        { number : "5.", title : "Earth's No Good.mp3", url : "05.mp3" },
        { number : "6.", title : "Ad Astra.mp3", url : "06.mp3" },
        { number : "7.", title : "Fear Your Friends (Feat. RONYN).mp3", url : "07.mp3" },
        { number : "8.", title : "Lucid.mp3", url : "08.mp3" },
        { number : "9.", title : "Forever.mp3", url : "09.mp3" },
        { number : "10.", title : "Better Skies.mp3", url : "10.mp3" },
      ],
    }
  },
  methods:{
    setCurrentSong(index, song){
      this.currentSong = song.url;
      this.currentSongIndex = index;
      document.getElementById('jonvoPlayer').src = "/music/"+song.url;
    },
    playNext() {
      this.currentSongIndex = this.currentSongIndex + 1;
      this.currentSong = this.songs[this.currentSongIndex].url;
      document.getElementById('jonvoPlayer').src = "/music/"+this.currentSong;
      // document.getElementById('jonvoPlayer').src = "/music/"+song.url;
      this.$refs.audio.play();
    }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.mainpage::-webkit-scrollbar {
  display: none;
  overflow-y: scroll;
  min-height: 900px;
}
.jonvo-main-body{
  max-height: 800px;
  overflow-y: scroll;
  width: 102%;
  opacity: .9;
}
.jonvo-bio-content{
  padding:30px;
  padding-left:35px;
  line-height: 2;
}
.music-content{
  background-color:#d0d0d0;
  padding:30px;
  border-radius: 25px;
  padding-left:35px;
  box-shadow: 1px 2px 4px 0px #dfdfdf;
}
.mainpage{
  overflow-x: hidden;
  scrollbar-width: none;  /* Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #ffe8a8;
}
.jonvo-weblink{
  padding-right:15px;
}
</style>
