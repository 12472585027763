<template>
  <div :style="backgroundStyle" class="background-fade">
    <HelloWorld msg="Welcome to Your Vue.js App"/>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  },
  data() {
    return {
      images: [
        'url("https://jonvo-assets.s3.us-east-2.amazonaws.com/album.png")',
        'url("https://jonvo-assets.s3.us-east-2.amazonaws.com/albumbw.png")',
      ],
      currentIndex: 0,
    };
  },
  computed: {
    backgroundStyle() {
      return {
        backgroundImage: this.images[this.currentIndex],
        height: '100vh',  // Adjust as needed
        width: '100vw',   // Adjust as needed
        transition: 'background-image 5s',  // Adjust for a smoother or faster fade
      };
    },
  },
  mounted() {
    setInterval(() => {
      this.currentIndex = (this.currentIndex + 1) % this.images.length;
    }, 10000);  // 10 seconds
  },
}
</script>

<style>
@font-face {
  font-family: myFirstFont;
  src: url('assets/fonts/VictorMono-VariableFont_wght.ttf');
}
#app {
  font-family: myFirstFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 60px;
}
.background-fade {
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
}
</style>
